<template>
    <div class="page">
        <Part1 />
        <div class="mains">
        <div class="c-left">
            <div class="border"></div>
            <p class="fund">绩效归因</p>
            <div class="rate">
                <!-- <p class="txt">
                公募基金投资工具包含基金评级、评分，基金经理评级、评分，基金绩效与风险指标筛选、基金风险因子模型，以及基金组合构建、优化与回测等数据结果与功能。
                </p> -->
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic" v-show="subimg"><img :src="iconimg1" @click="changepic" v-show="!subimg">组合评测</p>
                <p class="txt" v-if="showselect">
                  领先的组合实时绩效分析与报告制作工具，为您提供多种绩效归因模型、风险因子、基准指数和报告模板，满足对股票、债券、期货等各资产类型；理财产品、公募基金、私募基金等不同投资工具；以及指数跟踪、指数增强和主动管理等不同投资策略的绩效分析和评测需求。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic1" v-show="subimg1"><img :src="iconimg1" @click="changepic1" v-show="!subimg1">绩效归因</p>
                <p class="txt" v-if="showfeng">
                  丰富的绩效归因模型和灵活的技术框架支持基于资产板块类别、产品层级、组合风险因子暴露以及以上三种方式混合的绩效归因方法，涵盖股票、利率、信用、衍生品、外汇各类型资产，支持自下而上的归集与自上而下的下钻分析。归因模型糅合Brinson的配置与选择效应方法，Fama-Barra的因子归因，Campisi和利率曲线因子和其他债券因子归因等多种模型。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic2" v-show="subimg2"><img :src="iconimg1" @click="changepic2" v-show="!subimg2">报告系统</p>
                <p class="txt" v-if="showyou">
                  通过灵活的报告系统，您可以从绩效分析、绩效归因、风险监控、风险分解、情景风析等50项功能模块，自由组合排列相应的图、表、分析文案，制作基金绩效报告(基金管理)、基金选择与评测报告（FoF/MoM）、客户投资报告（基金销售）等。
                </p>
            </div>
        </div>
        <div class="c-right">
            <img src="../../assets/product/绩效归因.png" alt="" style="width:474px;height:317px">
        </div>
    </div>
        <ApplyForm/>
    </div>
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'

export default {
    components: { Part1, ApplyForm },
    data() {
        return {
            iconimg:require('../../assets/sub.png'),
            iconimg1:require('../../assets/plug.png'),
            iconimg2:require('../../assets/plug.png'),
            subimg:true,
            subimg1:false,
            subimg2:false,
            showselect:true,
            showfeng:false,
            showyou:false,
        }
    },
    methods:{
        changepic(){
            this.subimg=!this.subimg
            this.showselect=!this.showselect
        },
        changepic1(){
            this.subimg1=!this.subimg1
            this.showfeng=!this.showfeng
        },
        changepic2(){
            this.subimg2=!this.subimg2
            this.showyou=!this.showyou
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 400px;
    // background:yellow;
    padding:79px 0 90px 0;
    margin:0 auto;
    .c-left{
        height:400px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .rate{
            width:644px;
            // height:40px;
            // padding:16px;
            border-bottom:solid 1px #ddd;
        }
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .select{
            width:658px;
            height:136px;
            padding:20px 0 0 10px;
            border-bottom:solid 1px #ddd;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .font16{
            color:#2E5082;
            font-size:16px;
            text-align:left;
            font-weight:600;
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
img{
    padding-right:5px;
    margin:0;
}
</style>